body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #000; /* AMOLED dark background */
}

.coming-soon {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url('https://img.freepik.com/free-vector/abstract-black-texture-background-hexagon_206725-413.jpg') no-repeat center center/cover; /* Replace with actual background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay for contrast */
}

.content {
  position: relative;
  text-align: center;
  color: #fc0; /* Brand color */
}

.logo {
  max-width: 200px;
  margin-bottom: 20px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.countdown {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: white;
}

.social-links {
  font-size: 2rem;
}

.social-links a {
  color: #fc0;
  margin: 0 15px;
  text-decoration: none;
}

.social-links a:hover {
  color: #fff; /* Change color on hover */
}
